import { Response } from "../../types/api";
import { Api } from "../api-store";
import { ProductsQueryParams } from "../product";
import {
  ContactUsMessage,
  ContactUsMessageBody,
  Faq,
  SubscribeNewsLetterBody,
} from "./support-types";

export const SupportApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    sendMessage: builder.mutation<
      Response<ContactUsMessage>,
      ContactUsMessageBody
    >({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/contact_us`,
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    subscribeNewsLetter: builder.mutation<
      Response<boolean>,
      SubscribeNewsLetterBody
    >({
      invalidatesTags: [],
      query: ({ ...data }) => ({
        url: `/support/newsletter-subscriptions`,
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),

    messages: builder.query<Response<ContactUsMessage[]>, undefined>({
      providesTags: [],
      query: () => ({
        url: `/support/start-conversation`,
        method: "GET",
      }),
    }),
    faqs: builder.query<Response<Faq[]>, ProductsQueryParams | undefined>({
      providesTags: [],
      query: (params) => ({
        url: `/faq`,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useSendMessageMutation,
  useMessagesQuery,
  useSubscribeNewsLetterMutation,
  useFaqsQuery,
} = SupportApi;
