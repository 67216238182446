import { ApiOptions } from "~/old-app/types/api";
import { useFaqsQuery, useMessagesQuery } from "./support-api";
import { ProductsQueryParams } from "../product";
// import { useInfluencerProfileQuery } from "./support-api";

export const useMessages = (options?: ApiOptions) => {
  const { data, isLoading, isFetching } = useMessagesQuery(undefined, options);
  return {
    messages: data?.response?.data,
    isLoading,
    isFetching,
  };
};
export const useFaqs = (params?: ProductsQueryParams) => {
  const { data, isLoading, isFetching } = useFaqsQuery(params);
  return {
    faqs: data?.response?.data,
    isLoading,
    isFetching,
  };
};
